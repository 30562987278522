<template>
  <div>
    <div class="pc">
      <div class="lp-divider-main" style="padding-top:60px"></div>
      <div class="padding-top-16 flex-align">
        <div class="body0-bold main">사이트 개설 정보</div>
      </div>
      <div class="lp-divider-gray2 padding-top-16"></div>
      <div class="grid-input">
        <div class="body3-medium sub2">내 서비스의 이름</div>
        <div>
          <input class="input font-primary" type="text"
                 placeholder="텍스트" :class="{'input-error': vErr.name}"
                 style="width:100%" maxlength="30"
                 v-model="order.service_name"/>
          <div class="body5 sub4" style="margin-top:4px">*서비스 개설 후 수정할 수 있습니다</div>
        </div>
      </div>
      <div class="grid-input">
        <div class="body3-medium sub2">내 서비스 주소</div>
        <div>
          <div class="flex-align margin-top-8 input-sub3"
               :class="{'input-error': vErr.key}"
               style="border-radius:8px">
            <input class="input font-primary" type="text"
                   placeholder="영문, 숫자" id="service-key" autocomplete="new-password"
                   style="width:100%;border:0" maxlength="30"
                   @input="onKeyupServiceId" @blur="validKey"
                   v-model="order.key"/>
            <div style="padding:0 16px 0 0">.launchpack.co.kr</div>
          </div>
          <div class="err" v-if="err!==''">{{ err }}</div>
          <div class="body5 sub4" style="margin-top:4px">*서비스 주소 영문명은 변경되지 않습니다</div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <div class="h8 main margin-bottom-16">사이트 개설 정보</div>
      <div class="grid-input">
        <div class="body5-medium sub2">내 서비스의 이름</div>
        <div>
          <input class="input font-primary" type="text"
                 placeholder="텍스트" :class="{'input-error': vErr.name}"
                 style="width:100%" maxlength="30"
                 v-model="order.service_name"/>
          <div class="body5 sub4" style="margin-top:4px">*서비스 개설 후 수정할 수 있습니다</div>
        </div>
      </div>
      <div class="grid-input">
        <div class="body5-medium sub2">내 서비스 주소</div>
        <div>
          <div class="flex-align input-sub3"
               :class="{'input-error': vErr.key}"
               style="border-radius:8px">
            <input class="input font-primary" type="text"
                   placeholder="영문, 숫자" id="service-key" autocomplete="new-password"
                   style="width:100%;border:0" maxlength="30"
                   @input="onKeyupServiceId" @blur="validKey"
                   v-model="order.key"/>
            <div style="padding:0 16px 0 0">.launchpack.co.kr</div>
          </div>
          <div class="err" v-if="err!==''">{{ err }}</div>
          <div class="body5 sub4" style="margin-top:4px">*서비스 주소 영문명은 변경되지 않습니다</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";

  export default {
    name: "InfoCreateService",
    mixins: [
      UserAPIMixin
    ],
    props: {
      order: {
        type: Object
      }
    },
    data() {
      return {
        err: '',
        vErr: {
          name: false,
          key: false
        }
      }
    },
    methods: {
      validKey() {
        this.vErr.key = false;
        this.err = '';
        this.request.user.post('ui/super_builder/check_service', {key: this.order.key}).then(res=>{
          if(res.data.result) {
            this.vErr.key = true;
            this.err = '다른 주소를 입력해주세요.';
          }
        })
      },
      onKeyupServiceId: function (e) {
        // if(/[0-9a-z]/.test(e.target.value[e.target.value.length-1])) {
        //   this.params.service_id = e.target.value;
        // }

        // eslint-disable-next-line no-useless-escape
        let regExp = /[\{\}\[\]\/?_.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
        // 특수문자 예외처리
        if (regExp.test(e.target.value)) {
          this.order.key = e.target.value.replace(regExp, '');
        } else if (e.target.value.length > 0 && /[a-z]/.test(e.target.value[0]) && /[0-9a-z]/.test(e.target.value[e.target.value.length-1])) {
          this.order.key = e.target.value;
        } else {
          this.order.key = '';
        }
        this.order.key = this.order.key.toLowerCase();
      },
    }
  }
</script>

<style lang="stylus" scoped>
  .grid-input
    margin-top 24px
    display grid
    grid-template-columns 290px 1fr

  .mobile
    .grid-input
      margin-top 20px
      grid-template-columns 1fr
      display grid
      grid-row-gap 4px
    input[type=text]
      font-size 13px

  .input-sub3
    border 1px solid #e3e3e3
  .input-error
    border 1px solid #ff6600

  .err
    color red
    font-size 11px
    margin-top 4px
    margin-left 4px
</style>
